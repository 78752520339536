import "./App.css";
import ApiService from "./services/apiService";
import CustomForm from "./pages/CustomForm";
import Alert from "./components/Alert";
import ReactPixel from "react-facebook-pixel";
import { useState, useEffect } from "react";
import { getText, setLanguage } from "./services/languageService";
function App() {
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({ label: "LOADING", data: {} });
    const [input, setInput] = useState();
    const [initialValues, setInitialValues] = useState();
    const [params, setParams] = useState({
        bg_color: "#ffffff",
        btn_color: "orange",
        campaign: 0,
        step: 1,
        maxSteps: 1,
    });

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };

    useEffect(async () => {
        //loading from data
        let searchParams = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let { id, frameId, affiliate_id, url, ref_id, lead } = searchParams;

        let initial = {};

        setInterval(function () {
            window.parent.postMessage(
                // get height of the content
                {
                    height: document.body.scrollHeight,
                    id: frameId,
                },
                // set target domain
                "*"
            );
        }, 150);
        try {
            let data = await ApiService.getForm(id);
            let stepNumber = 1;
            data.fields.map((field) => {
                if (!field.step) {
                    field.step = 1;
                }

                if (field.step > stepNumber) {
                    stepNumber = field.step;
                }
            });

            if (lead) {
                try {
                    lead = JSON.parse(lead);
                    initial = lead;
                } catch (e) {
                    lead = {};
                }
            } else {
                lead = {};
            }

            data.fields.map((field) => {
                if (field.value && !lead[field.id]) {
                    initial[field.id] = field.value;
                }
            });

            setInitialValues(initial);

            setParams({
                ref_id,
                source: url,
                fields: data.fields,
                btn_color: data.settings.btnColor,
                btn_text: data.settings.btnText,
                access_token: data.settings.access_token,
                country: data.settings.country || "IT",
                extraHTML: data.extraHTML|| "",
                pixelId: data.settings.pixelId,
                frameId,
                bg_color: data.settings.bgColor,
                affiliate_id,
                success: data.settings.successMessage,
                notAllowed: data.notAllowed || [],
                maxSteps: stepNumber,
                lead,
                step: 1,
            });

            setInput(data.fields);
            setState({
                label: "RENDER",
                data: {},
            });

            setLanguage(data.settings.country || "IT")

            ReactPixel.init(data.settings.pixelId);
            ReactPixel.pageView();
            ReactPixel.track("PageView");
        } catch (e) {
            console.error(e);
            setState({
                label: "ERROR",
                data: {
                    title: getText("error_bad_title"),
                    type: "warning",
                    description: getText("error_bad_description"),
                },
            });
        } finally {
            setLoading(false);
        }
    }, [setInput, setState, setLoading]);

    const loadInput = (inputFields, lead) => {
        /*  let inputs = inputFields.filter((inp) => {
            return inp.step == params.step;
        });

        if (lead) {
            inputs = inputs.map((inp) => {
                inp.value = lead[inp.id] || inp.value || undefined;
                return inp;
            });
        } */
    };

    const handleSubmission = async (result) => {
        try {
            console.log("SUBMIT");
            setLoading(true);

            if (params.step < params.maxSteps) {
                params.step = params.step + 1;
                setParams(params);
                // loadInput(params.fields, params.lead);
            } else {
                let error = false;
                Object.keys(result).map((k) => {
                    if (params.notAllowed[k]) {
                        if (params.notAllowed[k].values.includes(result[k])) {
                            setState({
                                label: "ERROR",
                                data: {
                                    title: params.notAllowed[k].title,
                                    type: "warning",
                                },
                            });

                            error = true;
                        }
                    }
                });

                if (error) {
                    return;
                }

                let data = await ApiService.postData(result, params);

                setState({
                    label: "DONE",
                    data: {},
                });

                ReactPixel.track("CompleteRegistration");

                window.parent.postMessage(
                    // get height of the content
                    {
                        sender: "e-leads",
                        message: "done",
                        success: true,
                        id: data.id,
                    },
                    // set target domain
                    "*"
                );
            }
        } catch (e) {
            console.error(e);
            setState({
                label: "ERROR",
                data: {
                    title: getText("error_bad_title"),
                    type: "danger",
                    description: getText("error_bad_description"),
                },
            });
        } finally {
            setLoading(false);
        }
    };

    let content;
    switch (state.label) {
        case "ERROR":
            content = <Alert type={state.data.type} title={state.data.title} description={state.data.description}></Alert>;
            break;

        case "DONE":
            content = (
                <>
                    <Alert type="success" title={getText("thanks")} description={params.success}></Alert>
                    {/* <img height="1" width="1" style="display:none" src={`https://www.facebook.com/tr?id=${params.pixelId}&ev=CompleteRegistration&noscript=1`} /> */}
                </>
            );
            break;

        case "RENDER":
            let progressBar = <></>;
            if (params.maxSteps > 1) {
                progressBar = (
                    <>
                        <div className="is-size-7 has-text-centered">
                            {params.step}/{params.maxSteps}
                        </div>
                        <progress class="progress is-small is-info" value={params.step} max={params.maxSteps}></progress>
                    </>
                );
            }
            content = (
                <>
                    {progressBar}
                    <CustomForm fields={input} params={params} initialValues={initialValues || {}} cbSubmit={handleSubmission} />
                </>
            );
            break;

        default:
            content = <></>;
            break;
    }

    return (
        <div className="form-container" style={{ backgroundColor: params.bg_color }}>
            <div className="container">
                {!loading ? (
                    <></>
                ) : (
                    <div className="loader-wrapper">
                        <div className="loader is-loading"></div>
                    </div>
                )}
                {content}
                <div className="mt-2 columns">
                    <div className="column has-text-centered has-text-success is-size-5">
                        <i className="fas fa-lock"></i> {getText("safe_data")}
                    </div>
                </div>
            </div>
            <span dangerouslySetInnerHTML={{__html: params.extraHTML}}></span>
            {/* <img height="1" width="1" style={{display:"none"}} src={`https://www.facebook.com/tr?id=${params.pixelId}&ev=PageView&noscript=1`} /> */}
        </div>
    );
}

export default App;
