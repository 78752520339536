import React from "react";
import PropTypes from "prop-types";

function SelectField(props) {
    return (
        <div className="field">
            <div className="label">{props.label}</div>
            <div className="control has-icons-right">
                <div
                    className={
                        props.error && props.touched[props.name]
                            ? "select is-fullwidth is-danger"
                            : "select is-fullwidth"
                    }
                >
                    <select
                        name={props.name}
                        defaultValue={props.value}
                        onBlur={props.handleBlur}
                        onChange={props.onChange}
                    >
                        <option></option>
                        {props.options.map((opt, index) => {
                            return (
                                <option key={index} value={opt}>
                                    {opt}
                                </option>
                            );
                        })}
                    </select>
                </div>

                {props.error && props.touched[props.name] && <p className="help is-danger">{props.error}</p>}
            </div>
        </div>
    );
}

SelectField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.array,
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

SelectField.defaultValue = {
    options: [],
};

export default SelectField;
