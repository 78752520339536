import React from "react";
import PropTypes from "prop-types";

function RadioButtonField(props) {
    return (
        <>
            <div className="label">{props.label}</div>
            {props.options.map((opt, index) => {
                return (
                    <div
                        className="field"
                        style={
                            props.error && props.touched[props.name] ? { color: "red", border: "2px solid red" } : {}
                        }
                    >
                        <div>
                            <label className="radio">
                                <input
                                    type="radio"
                                    name={props.name}
                                    value={opt}
                                    checked={opt === props.value}
                                    onBlur={props.handleBlur}
                                    onChange={props.onChange}
                                />

                                <span className="ml-3">{opt}</span>
                            </label>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

RadioButtonField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.array,
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default RadioButtonField;
