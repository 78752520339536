import { Component } from "react";

let development = {
    apiUrl: "http://localhost:8000/",
    wsUrl: "http://localhost:8000/ws/",
};

let production = {
    apiUrl: "https://webhook.e-leads.it/",
    wsUrl: "https://webhook.e-leads.it/ws",
};

class ConfigKeys extends Component {
    static keys() {
        var config;

        if (process.env.NODE_ENV === "production") {
            config = production;
        } else {
            config = development;
        }

        return config;
    }
}

export default ConfigKeys;
