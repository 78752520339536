import React from "react";
import PropTypes from "prop-types";
import { FieldContainer, Input } from "./_fieldStyles";

function TextField(props) {
    return (
        <div className="field">
            <div className="label">{props.label}</div>
            <div className="control has-icons-right">
                <input
                    type={props.type}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value || ""}
                    onBlur={props.handleBlur}
                    onChange={props.onChange}
                    className={props.error && props.touched[props.name] ? "input is-danger" : "input"}
                />
                {props.error && props.touched[props.name] && (
                    <span className="icon is-small is-right">
                        <i className="fas fa-exclamation-triangle"></i>
                    </span>
                )}
            </div>
            {props.error && props.touched[props.name] && <p className="help is-danger">{props.error}</p>}
        </div>
    );
}

TextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default TextField;
