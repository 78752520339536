import React from "react";
import PropTypes from "prop-types";

function TextAreaField(props) {
    return (
        <>
            <div className="label">{props.label}</div>
            <textarea
                className="textarea"
                type="text"
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                onBlur={props.handleBlur}
                onChange={props.onChange}
                style={props.error && props.touched[props.name] ? { color: "red", border: "2px solid red" } : {}}
            />
            {props.error && props.touched[props.name] && <p className="help is-danger">{props.error}</p>}
        </>
    );
}

TextAreaField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

TextAreaField.defaultProps = {
    label: "",
    name: "",
    placeholder: "",
    value: "",
    error: "",
    onChange: () => {},
};

export default TextAreaField;
