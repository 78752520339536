import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as yup from "yup";
import Fields from "../components";
import { createYupSchema } from "../utils/yupSchemaCreator";
import { FIELD_TYPES, VALIDATION_TYPES } from "../constants";
import { getText, setLanguage } from "../services/languageService";

function CustomForm(props) {
    const [isSubmitting, setSubmitting] = useState(false);
    const { fields, cbSubmit, params, initialValues } = props;

    setLanguage(params.country)
    let buttonStyle = {
        backgroundColor: params.btn_color || "green",
        color: "white",
    };

    fields.forEach((item) => {
        initialValues[item.id] = item.value || "";
    });

    let yupSchema = fields.reduce(createYupSchema, {});
    let finalSchema = {};
    fields.map((field) => {
        if (field.step == params.step) {
            finalSchema[field.id] = yupSchema[field.id];
        }
    });

    const validateSchema = yup.object().shape(finalSchema);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={cbSubmit}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formikProps) => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Fields fields={fields} step={params.step} formikProps={formikProps} />
                    <div className="columns is-mobile">
                        <div className="column has-text-right">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="button mb-2 mt-4  is-fullwidth is-medium"
                                style={buttonStyle}
                            >
                                {params.step < params.maxSteps ? getText("next") : params.btn_text || getText("submit")}
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}

CustomForm.propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            type: PropTypes.oneOf(FIELD_TYPES).isRequired,
            validationType: PropTypes.oneOf(VALIDATION_TYPES).isRequired,
            value: PropTypes.any,
            options: PropTypes.array,
            validations: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string.isRequired,
                    params: PropTypes.array.isRequired,
                })
            ),
        })
    ).isRequired,
    cbSubmit: PropTypes.func.isRequired,
};

export default CustomForm;
