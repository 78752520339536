import styled from "styled-components";

export const FieldContainer = styled.div``;

export const Input = styled.input``;

export const ZipcodeField = styled.input``;

export const Select = styled.select``;

export const TextArea = styled.textarea``;

export const Upload = styled.input``;

export const Label = styled.label``;
