import languages from "../utils/languages.json";

let language = "IT";

function getText(query) {
    return languages[language][query] || query;
}

function setLanguage(lang) {
    language = lang;
}

export { getText, setLanguage };
