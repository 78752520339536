import React, { useState } from "react";
import PropTypes from "prop-types";

function CheckboxField(props) {
    const [checkedItems, setCheckedItems] = useState(new Map());

    const handleCheckItem = (e) => {
        const { name, value } = e.target;
        let items = new Map(checkedItems);
        if (checkedItems.has(name)) {
            items.delete(name);
        } else {
            items.set(name, value);
        }
        setCheckedItems(items);
        props.setFieldValue(props.name, Array.from(items.values()).toString());
    };

    return (
        <>
            <div className="label">{props.label}</div>
            {props.options.map((opt, index) => {
                return (
                    <div
                        className="field"
                        style={
                            props.error && props.touched[props.name] ? { color: "red", border: "2px solid red" } : {}
                        }
                    >
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                name={props.name + "-" + index}
                                value={opt}
                                checked={checkedItems.get(props.name + "-" + index)}
                                onBlur={props.handleBlur}
                                onChange={handleCheckItem}
                            />
                            <span
                                className={props.name.includes("privacy") ? "ml-3 label-privacy" : "ml-3"}
                                dangerouslySetInnerHTML={{ __html: opt }}
                            ></span>
                        </label>
                    </div>
                );
            })}
        </>
    );
}

CheckboxField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

export default CheckboxField;
