import ConfigKeys from "../config/keys";
import { Component } from "react";
import axios from "axios";

let keys = ConfigKeys.keys();
let apiUrl = keys.apiUrl;
let wsUrl = keys.wsUrl;

export class ApiService extends Component {
    static async getForm(query) {
        try {
            const response = await axios.get(apiUrl + "form/" + query);
            return response.data || {};
        } catch (e) {
            throw e;
        }
    }

    static async getZipcode(query) {
        try {
            const response = await axios.get(apiUrl + "utilities/zip/" + query);
            return response.data || {};
        } catch (e) {
            throw e;
        }
    }

    static async postData(data, params) {
        try {
            /*  const response = await fetch(apiUrl + "form/" + query);
        let data = await response.json();
        return data || {}; */
            let lead = {
                affiliate_id: params.affiliate_id || "",
                offer_id: params.offer_id || "",
                access_token: params.access_token,
                ref_id: params.ref_id,

                first_name: data.first_name || "",
                last_name: data.last_name || "",
                phone: data.phone || "",
                email: data.email || "",
                formId: params.id,
                source: params.source,
            };

            delete data.first_name;
            delete data.last_name;
            delete data.phone;
            delete data.email;

            lead.extra = data;
            const response = await axios({
                method: "post",
                url: apiUrl + "ws/",
                data: lead,
            });

            return response.data;
        } catch (e) {
            throw e;
        }
    }
}

export default ApiService;
