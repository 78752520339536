import React from "react";

export default function Alert(props) {
    let classe = "notification";

    if (props.type) {
        classe = classe + " is-" + props.type;
    } else {
        classe = classe + " is-warning";
    }

    return (
        <div className={classe} style={{ textAlign: "center" }}>
            <strong>{props.title}</strong>
            <br></br>
            <span dangerouslySetInnerHTML={{ __html: props.description }}></span>
        </div>
    );
}
