import React, { useState } from "react";
import PropTypes from "prop-types";
import { FieldContainer, Input } from "./_fieldStyles";
import ApiService from "../services/apiService";
import Autocomplete from "react-search-autocomplete";

function ZipcodeField(props) {
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [input, setInput] = useState("");
    const [cityName, setCityName] = useState();

    const onChange = async (e) => {
        const input = e.currentTarget.value;
        setInput(input);
        setFiltered([]);
        const newFilteredSuggestions = input ? await ApiService.getZipcode(input) : [];

        setActive(0);
        setFiltered(newFilteredSuggestions);
        setIsShow(true);
        props.onChange(e);
    };

    const onClick = (suggestion) => {
        setActive(0);
        setFiltered([]);
        setIsShow(false);
        setInput(suggestion.zipcode);
        setCityName(suggestion.name);
        props.handleBlur({
            currentTarget: {
                value: suggestion.zipcode,
            },
            target: {
                value: suggestion.zipcode,
                name: props.name,
            },
        });
        props.onChange({
            currentTarget: {
                value: suggestion.zipcode,
            },
            target: {
                value: suggestion.zipcode,
                name: props.name,
            },
        });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter key
            setActive(0);
            setIsShow(false);
            setInput(filtered[active]);
        } else if (e.keyCode === 38) {
            // up arrow
            return active === 0 ? null : setActive(active - 1);
        } else if (e.keyCode === 40) {
            // down arrow
            return active - 1 === filtered.length ? null : setActive(active + 1);
        }
    };

    const renderAutocomplete = () => {
        if (isShow && input) {
            if (filtered.length) {
                return (
                    <ul className="autocomplete">
                        {filtered.map((suggestion, index) => {
                            let className;
                            if (index === active) {
                                className = "active";
                            }
                            return (
                                <li className={className} key={suggestion.name} onClick={() => onClick(suggestion)}>
                                    {"(" + suggestion.zipcode + ") "}
                                    {suggestion.name}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div className="no-autocomplete">
                        <em>Nessun elemento trovato</em>
                    </div>
                );
            }
        }
        return <></>;
    };

    return (
        <>
            <div className="field">
                <div className="label">{props.label}</div>
                <div className="control has-icons-right">
                    <input
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={input}
                        type={props.type}
                        name={props.name}
                        placeholder={props.placeholder}
                        className={props.error && props.touched[props.name] ? "input is-danger" : "input"}
                    />
                    {renderAutocomplete()}

                    {props.error && props.touched[props.name] && (
                        <span className="icon is-small is-right">
                            <i className="fas fa-exclamation-triangle"></i>
                        </span>
                    )}
                </div>
                {props.error && props.touched[props.name] && <p className="help is-danger">{props.error}</p>}
                {cityName ? <div className="label">Città: {cityName}</div> : <></>}
            </div>
        </>
    );
}

ZipcodeField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default ZipcodeField;
